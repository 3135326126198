import React, { useEffect, useState } from 'react';
import api from 'api';

const withWidgetData =
  (WrappedComponent) =>
  ({ userId, widgetId, ...props }) => {
    const [loading, setLoading] = useState(true);
    const [widgetData, setWidgetData] = useState({});

    useEffect(() => {
      const fetchWidgetData = async () => {
        try {
          const { data } = await api.getWidget({ userId, id: widgetId });
          const contentData = data.widget.content.data.attributes;

          setWidgetData(contentData);
        } catch (error) {
          console.error('Error fetching widget data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchWidgetData();
    }, [userId, widgetId]);

    return <WrappedComponent {...widgetData} loading={loading} {...props} />;
  };

export default withWidgetData;
