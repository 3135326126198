const DEFAULT_COLUMN_WIDTH = 300;
const DEFAULT_MAX_COLUMNS = 4;

/**
 * Generates a layout map for positioning widgets in a masonry grid.
 *
 * @param {Array<Object>} widgets - The array of widgets to be positioned in the grid.
 * Each widget should have `colSpan` and `rowSpan` properties to specify the widget's
 * width and height in grid units. `rowSpan` can be an object to support breakpoints.
 * @param {number} columnCount - The total number of columns available in the grid.
 * @param {string} activeBreakpoint - The current active breakpoint to determine rowSpan.
 * @returns {Object} An object containing:
 *   - `layoutMap` {Object}: A map of positions with keys as row and column indices
 *     (e.g., '0,0') and values as the widget index.
 *   - `rowCount` {number}: The total number of rows used to fit all widgets.
 */
export const generateLayoutMap = (widgets, columnCount, activeBreakpoint) => {
  const layoutMap = {};
  let maxRow = 0;

  /**
   * Checks if a widget can be placed at a specified position in the grid.
   *
   * @param {number} row - The starting row position to check.
   * @param {number} col - The starting column position to check.
   * @param {number} colSpan - The number of columns the widget spans.
   * @param {number} rowSpan - The number of rows the widget spans.
   * @returns {boolean} `true` if the widget can be placed at the specified position;
   * otherwise, `false`.
   */
  const canPlaceWidget = (row, col, colSpan, rowSpan) => {
    for (let r = 0; r < rowSpan; r++) {
      for (let c = 0; c < colSpan; c++) {
        if (layoutMap[`${row + r},${col + c}`] !== undefined) return false;
      }
    }

    return true;
  };

  /**
   * Places a widget in the grid layout map by marking the occupied cells.
   *
   * @param {number} row - The starting row position for the widget.
   * @param {number} col - The starting column position for the widget.
   * @param {number} colSpan - The number of columns the widget spans.
   * @param {number} rowSpan - The number of rows the widget spans.
   * @param {number} widgetIndex - The index of the widget in the widgets array.
   */
  const placeWidgetInMap = (row, col, colSpan, rowSpan, widgetIndex) => {
    for (let r = 0; r < rowSpan; r++) {
      for (let c = 0; c < colSpan; c++) {
        layoutMap[`${row + r},${col + c}`] = widgetIndex;
      }
    }
    maxRow = Math.max(maxRow, row + rowSpan);
  };

  widgets.forEach((widget, index) => {
    const colSpan = Math.min(widget.colSpan, columnCount);
    const rowSpan =
      typeof widget.rowSpan === 'object'
        ? widget.rowSpan[activeBreakpoint] || widget.rowSpan.default
        : widget.rowSpan;

    let placed = false;
    let row = 0;

    while (!placed) {
      for (let col = 0; col <= columnCount - colSpan; col++) {
        if (canPlaceWidget(row, col, colSpan, rowSpan)) {
          placeWidgetInMap(row, col, colSpan, rowSpan, index);
          placed = true;
          break;
        }
      }
      row++;
    }
  });

  return { layoutMap, rowCount: maxRow };
};

/**
 * Calculates the number of columns that can fit in a container.
 *
 * @param {number} width - The width of the container in pixels.
 * @param {number} [columnWidth=300] - The width of a single column in pixels.
 * @param {number} [maxColumns=4] - The maximum number of columns allowed.
 * @returns {number} The calculated number of columns that can fit in the container.
 */
export const calculateColumnCount = (
  width,
  columnWidth = DEFAULT_COLUMN_WIDTH,
  maxColumns = DEFAULT_MAX_COLUMNS
) => Math.min(maxColumns, Math.floor(width / columnWidth));

/**
 * Maps a layout of widgets from the layoutMap generated by `generateLayoutMap`.
 *
 * @param {Array<Object>} widgets - The array of widgets to be positioned in the grid.
 * Each widget should have properties such as `widgetComponent`, `colSpan`, and `rowSpan`.
 * @param {Object} layoutMap - The layout map object with grid coordinates as keys and
 * widget indices as values.
 * @param {number} columnCount - The number of columns available in the grid.
 * @param {number} rowCount - The total number of rows occupied by widgets in the layout.
 * @param {string} currentBreakpoint - The active breakpoint to determine rowSpan.
 * @returns {Array<Object>} An array of objects representing the layout of each widget,
 * with properties `key`, `widgetComponent`, `colSpan`, and `rowSpan`.
 */
export const mapLayout = (
  widgets,
  layoutMap,
  columnCount,
  rowCount,
  currentBreakpoint
) => {
  const finalLayout = [];
  const renderedWidgets = new Set();

  for (let row = 0; row < rowCount; row++) {
    for (let col = 0; col < columnCount; col++) {
      const widgetIndex = layoutMap[`${row},${col}`];

      if (widgetIndex !== undefined && !renderedWidgets.has(widgetIndex)) {
        const { widgetComponent, colSpan, rowSpan } = widgets[widgetIndex];
        const adjustedColSpan = Math.min(colSpan, columnCount);

        const effectiveRowSpan =
          typeof rowSpan === 'object'
            ? rowSpan[currentBreakpoint] || rowSpan.default
            : rowSpan;

        finalLayout.push({
          key: `${widgetIndex}-${row}-${col}`,
          widgetComponent,
          colSpan: adjustedColSpan,
          rowSpan: effectiveRowSpan,
        });
        renderedWidgets.add(widgetIndex);
      }
    }
  }

  return finalLayout;
};
