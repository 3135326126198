import React, { useRef, useEffect, useState } from 'react';

import useBreakpoint from 'hooks/use-breakpoint';

import {
  generateLayoutMap,
  mapLayout,
} from 'containers/user/home/components/masonry-grid/masonry-grid.helper';

import { useResponsiveColumns } from 'containers/user/home/components/masonry-grid/hooks/use-responsove-columns';

const MasonryGrid = ({ widgets }) => {
  const gridRef = useRef(null);
  const columnWidth = 300;
  const rowHeight = 36;
  const maxColumns = 4;

  const currentBreakpoint = useBreakpoint();
  const columnCount = useResponsiveColumns(gridRef, columnWidth, maxColumns);
  const [layout, setLayout] = useState([]);

  useEffect(() => {
    const { layoutMap, rowCount } = generateLayoutMap(widgets, columnCount);
    const finalLayout = mapLayout(
      widgets,
      layoutMap,
      columnCount,
      rowCount,
      currentBreakpoint
    );

    setLayout(finalLayout);
  }, [widgets, columnCount, currentBreakpoint]);

  return (
    <div
      ref={gridRef}
      className="grid py-4 mx-auto"
      data-testid="masonry-grid"
      style={{
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        gridAutoRows: `${rowHeight}px`,
      }}
    >
      {layout.map(({ key, widgetComponent, colSpan, rowSpan }) => (
        <div
          key={key}
          className="masonry-item"
          style={{
            gridColumn: `span ${colSpan}`,
            gridRow: `span ${rowSpan}`,
          }}
        >
          {widgetComponent}
        </div>
      ))}
    </div>
  );
};

export default MasonryGrid;
