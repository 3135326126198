import React from 'react';

import withWidgetData from 'containers/user/home/components/widgets/HOC/with-widget-data-hoc';
import Announcement, {
  announcementConfig,
} from 'containers/user/home/components/widgets/announcement';

const AnnouncementWithData = withWidgetData(Announcement);

const widgetMap = {
  ANNOUNCEMENT: {
    component: AnnouncementWithData,
    config: announcementConfig,
  },
};

const getWidgetsSet = (responseData, currentUser) =>
  responseData.map((item) => {
    const widgetType = item.widget.type;
    const { component: WidgetComponent, config } = widgetMap[widgetType] || {};

    return {
      id: item.id,
      widgetComponent: WidgetComponent ? (
        <WidgetComponent
          userId={currentUser.data.oktaUserId}
          widgetId={item.widget.resourceId}
        />
      ) : null,
      ...config,
    };
  });

export default getWidgetsSet;
