import React, { useEffect, useState } from 'react';
import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import MasonryGrid from 'containers/user/home/components/masonry-grid/masonry-grid';
import Loader from 'components/partials/loader/loader';

import getWidgetsSet from 'containers/user/home/components/widgets/helpers/helpers';

const Home = () => {
  const currentUser = useCurrentUser();
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWidgets = async () => {
      setLoading(true);
      try {
        const { data: responseData } = await api.getAllWidgets({
          userId: currentUser.data.oktaUserId,
        });

        const formattedWidgets = getWidgetsSet(responseData, currentUser);

        setWidgets(formattedWidgets);
      } catch (error) {
        console.error('Error fetching widgets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWidgets();
  }, [currentUser.data.oktaUserId]);

  return (
    <ResponsiveWrapper customClass="sm:max-w-[880px]">
      <div className="my-5 font-black font-family text-4xl text-indigo-02">
        Welcome to MARKETview
      </div>
      <div className="relative">
        <Loader loading={loading} overlay="transparent" fullScreen={false} />
        {widgets.length ? (
          <MasonryGrid widgets={widgets} />
        ) : (
          !loading && (
            <div className="text-center text-3xl">No widgets to display</div>
          )
        )}
      </div>
    </ResponsiveWrapper>
  );
};

export default Home;
