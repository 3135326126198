import React from 'react';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';
import Icons from 'assets/icons';

export const announcementConfig = {
  colSpan: 4,
  rowSpan: {
    default: 2,
    sm: 3,
    xs: 3,
    tablet: 3,
    mobile: 5,
  },
};

const typeStyles = {
  Warning: {
    bg: 'bg-yellow-06',
    titleColor: 'text-yellow-05',
    border: '',
    icon: <Icons.Info customClass="h-4 w-4 text-yellow-05" />,
  },
  Success: {
    bg: 'bg-green-06',
    titleColor: 'text-green-07',
    border: '',
    icon: <Icons.Success customClass="h-4 w-4 text-green-07" />,
  },
  Info: {
    bg: 'bg-gray-00',
    titleColor: 'text-indigo-02',
    border: 'border border-gray-05',
    icon: <Icons.Info customClass="h-4 w-4 text-indigo-02" />,
  },
};

const SkeletonLoader = () => (
  <div
    aria-label="Loading content"
    role="status"
    className="h-full w-full p-5 lg:py-3 bg-gray-100 rounded-md animate-pulse flex flex-col gap-y-3 tablet:gap-y-0 justify-between tablet:flex-row"
  >
    <span className="sr-only">Loading</span>
    <div className="flex flex-1 flex-col gap-y-2">
      <div className="flex items-center gap-x-3">
        <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
        <div className="h-6 w-1/4 bg-gray-300 rounded"></div>
      </div>
      <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
      <div className="h-4 w-2/3 bg-gray-300 rounded"></div>
    </div>
    <div className="h-8 w-32 bg-gray-300 rounded mt-3 tablet:mt-0 tablet:ml-auto"></div>
  </div>
);

const Announcement = ({ title, description, type = 'Info', loading }) => {
  if (loading) {
    return <SkeletonLoader />;
  }

  const { bg, titleColor, border, icon } = typeStyles[type] || typeStyles.Info;

  return (
    <div
      className={classNames(
        'h-full w-full p-5 lg:py-3 rounded-md flex flex-col tablet:flex-row justify-between gap-x-2 items-center',
        bg,
        border
      )}
    >
      <div className="flex  tablet:justify-start mobile:flex-col mobile:items-start">
        <div className="flex items-center gap-x-2 mb-2 tablet:mb-0">
          {icon}
          <p className={classNames('font-semibold', titleColor)}>{title}</p>
        </div>
        <p className="text-dark-01 text-base font-normal">{description}</p>
      </div>
      <div className="self-start tablet:self-center mt-3 tablet:mt-0 tablet:ml-2 shrink-0">
        <CustomButton
          variant="secondary"
          size="sm"
          customClass="enabled:hover:bg-yellow-06"
          onClick={() => {
            console.log('close');
          }}
        >
          Ok, don't show this again
        </CustomButton>
      </div>
    </div>
  );
};

export default Announcement;
